import { DateTime } from "luxon";
import { calendar_days } from "../components/sequences/Utils";
import { fonts } from "./fonts";

const _prepareData = (
  date_elem,
  data,
  saturday,
  length,
  sequences_list,
  school,
) => {
  const week_start_date = parseInt(date_elem.compDate);
  const week_end_date = parseInt(date_elem.compEndDate);
  const filtered = [];
  let max_hour = 0;
  let max_day = 4; // starts with 0 : monday -> saturday 5

  if (saturday) {
    max_day = 5;
  }

  data.forEach((element) => {
    element.data.forEach((list) => {
      if (
        week_start_date >= parseInt(list.calc_date) &&
        week_end_date <= parseInt(list.calc_end_date)
      ) {
        // gucken ob in der sequence list eintraege dazu vorhanden sind:
        const list_items = [];
        sequences_list.forEach((sql) => {
          if (Number(sql.sequence_item_id) === Number(list.id)) {
            if (sql.timeslots && sql.timeslots.length > 0) {
              // datumlisten neu generieren falls diese nach bestimmten umständen noch nicht aktualisiert wurden.
              const calendar_data = calendar_days(
                list.calc_date,
                list.calc_end_date,
                element.scooldays,
                school.holidays,
                school.calendarMap,
              );

              sql.timeslots.forEach((st, idx) => {
                // eintraege zum datumsrange filtern
                if (calendar_data[idx]) {
                  const st_data = { ...st };
                  st_data.date = calendar_data[idx].date;
                  st_data.id = calendar_data[idx].day_id;
                  const _tdate = DateTime.fromISO(st_data.date).toFormat(
                    "yMMdd",
                  );
                  const day_number = new Date(st_data.date).getDay();
                  st_data.day = parseInt(day_number) - 1;
                  if (_tdate >= week_start_date && _tdate <= week_end_date) {
                    const list_str = {
                      text: [
                        {
                          text: `${element.classroom_title}\n`,
                          fontSize: 8,
                          bold: true,
                        },
                        {
                          text: `${element.title} `,
                          fontSize: 8,
                          bold: false,
                        },
                        { text: `\n${st.title}`, fontSize: 15, bold: true },
                      ],
                      border: [false, true, false, true],
                      style: "textblock",
                    };
                    st_data.str = list_str;
                    list_items.push(st_data);
                  }
                }
              });
            }
          }
        });

        const str = {
          text: [
            { text: `${element.classroom_title}\n`, fontSize: 8, bold: true },
            { text: `${element.title} `, fontSize: 8, bold: false },
            // { text:  ' Raum 12 \n', fontSize: 8, bold: false },
            { text: `\n${list.title}`, fontSize: 15, bold: true },
          ],
          border: [false, true, false, true],
          style: "textblock",
        };

        const obj = {
          classname: element.classroom_title,
          subjectname: element.title,
          sequencename: list.title,
          schooldays: element.schooldays,
          str,
          list_items,
        };
        filtered.push(obj);
      }
    });
    element.schooldays.forEach((hours) => {
      if (parseInt(hours.hour) > max_hour) max_hour = parseInt(hours.hour);
    });
  });

  // sort by hours
  const hours_list = [];
  for (let i = 0; i <= max_hour; i += 1) {
    const tmp_list = [];
    filtered.forEach((elem) => {
      elem.schooldays.forEach((sd) => {
        if (parseInt(sd.hour) === i) {
          tmp_list.push({
            ...sd,
            str: elem.str,
            list: elem.list_items,
          });
        }
      });
    });
    hours_list[i] = tmp_list;
  }

  const rows = [];
  const plain = {
    text: "",
    border: [false, true, false, true],
  };

  hours_list.forEach((elem, index) => {
    elem.sort((a, b) => +b.day < +a.day);
    const list = [];
    elem.forEach((dayrow) => {
      const day = parseInt(dayrow.day);
      if (day <= max_day) {
        let obj = { ...dayrow.str };
        if (dayrow.list && dayrow.list.length > 0) {
          dayrow.list.forEach((_items) => {
            if (Number(_items.day) === day && _items.title) {
              obj = { ..._items.str };
            }
          });
        }
        list[day] = obj;
      }
    });

    for (let i = 0; i <= max_day; i += 1) {
      if (typeof list[i] === "undefined") {
        list[i] = plain;
      }
    }
    list.unshift({
      text: `${index + 1}.`,
      border: [false, true, false, true],
      fontSize: 11,
      bold: false,
      style: "numberfirst",
    });
    rows[index] = list;
  });
  return rows;
};

export default (
  date_element,
  data_items,
  school,
  user,
  saturday,
  sequences_list,
) => {
  const text_zeitraum = `${date_element.date.substr(
    0,
    3,
  )} bis ${date_element.end_date.substr(0, 3)} ${
    date_element.month_name
  } ${date_element.end_date.substr(6, 4)}`;
  const tableHeaders = [
    { text: "", style: "tableHeader", border: [false, true, false, true] },
    {
      text: "Montag",
      style: "tableHeader",
      border: [false, true, false, true],
    },
    {
      text: "Dienstag",
      style: "tableHeader",
      border: [false, true, false, true],
    },
    {
      text: "Mittwoch",
      style: "tableHeader",
      border: [false, true, false, true],
    },
    {
      text: "Donnerstag",
      style: "tableHeader",
      border: [false, true, false, true],
    },
    {
      text: "Freitag",
      style: "tableHeader",
      border: [false, true, false, true],
    },
  ];

  let tableWidths = [20, "*", "*", "*", "*", "*"];

  if (saturday) {
    tableHeaders.push({
      text: "Samstag",
      style: "tableHeader",
      border: [false, true, false, true],
    });
    tableWidths = [20, "*", "*", "*", "*", "*", "*"];
  }
  const seq_formated = _prepareData(
    date_element,
    data_items,
    saturday,
    tableWidths.length,
    sequences_list,
    school,
  );
  const documentDefinition = {
    footer(page, pages) {
      return {
        margin: [40, 0, 40, 0],
        columns: [
          {
            bold: true,
            fontSize: 8,
            text: "Unterschrift: _________________________________________",
          },
          {
            alignment: "right",
            text: [
              { text: "Seite ", bold: true },
              { text: page.toString() },
              " von ",
              { text: pages.toString() },
            ],
            fontSize: 8,
          },
        ],
      };
    },
    header: {
      margin: 40,
      columns: [
        {
          width: "*",
          text: [
            { text: `${school.school}\n`, fontSize: 8, bold: true },
            {
              text: `${school.street} ${school.nr}\n`,
              fontSize: 8,
              bold: false,
            },
            {
              text: `${school.zip} ${school.location}`,
              fontSize: 8,
              bold: false,
            },
          ],
          style: "header_left",
        },
        {
          width: "*",
          text: [
            { text: "Wochenplan\n", fontSize: 18, bold: true },
            { text: "Zeitraum ", fontSize: 8, bold: false },
            { text: `${text_zeitraum}\n\n `, fontSize: 8, bold: true },
          ],
          alignment: "center",
          style: "header_center",
        },
        {
          width: "*",
          text: [
            { text: "Lehrkraft: ", fontSize: 8, bold: false },
            { text: user.name, fontSize: 8, bold: true },
          ],
          alignment: "right",
          style: "header_right",
        },
      ],
    },
    pageSize: "A4",
    pageOrientation: "landscape",
    content: [
      {
        style: "tableFull",
        table: {
          widths: tableWidths,
          heights(row) {
            if (row === 0) {
              return 25;
            }
            return 60;
          },
          headerRows: 1,
          dontBreakRows: true,
          keepWithHeaderRows: 1,
          body: [[...tableHeaders], ...seq_formated],
        },
        layout: {
          hLineWidth(i, node) {
            return i === 0 || i === node.table.body.length ? 0.5 : 0.25;
          },
          vLineWidth(i, node) {
            return i === 0 || i === node.table.widths.length ? 0.5 : 0.25;
          },
          hLineColor(i, node) {
            return i === 0 || i === node.table.body.length ? "black" : "black";
          },
          vLineColor(i, node) {
            return i === 0 || i === node.table.widths.length
              ? "black"
              : "black";
          },
          // paddingLeft: function(i, node) { return 4; },
          // paddingRight: function(i, node) { return 4; },
          // paddingTop: function(i, node) { return 2; },
          // paddingBottom: function(i, node) { return 2; },
          // fillColor: function (i, node) { return null; }
        },
      },
    ],
    styles: {
      header: {
        fontSize: 18,
        bold: true,
        margin: [0, 0, 0, 10],
      },
      subheader: {
        fontSize: 16,
        bold: true,
        margin: [0, 10, 0, 5],
      },
      tableFull: {},
      tableHeader: {
        bold: false,
        fontSize: 11,
        color: "black",
        margin: [0, 5, 0, 5],
      },
      rooText: {
        margin: [10, 10, 10, 15],
      },
      textblock: {
        margin: [0, 5, 0, 5],
      },
      numberfirst: {
        margin: [0, 25, 0, 0],
      },
      footer_left: {
        margin: [40, 0, 0, 0],
        fontSize: 8,
        bold: false,
      },

      header_left: {
        // margin: [0, 20, 0, 40],
      },
      header_center: {
        // margin: [40, 40, 40, 40],
      },
      header_right: {
        // margin: [0, 20, 40, 40],
      },
    },
    pageMargins: [40, 90, 40, 60],
  };

  let str = "Wochenplan";
  str = str.replace(/\s/g, "_");
  str = str.replace(",", "_");
  str = str.replace(".", "_");
  str += ".pdf";
  window.pdfMake.createPdf(documentDefinition, null, fonts).download(str);
};
